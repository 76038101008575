import { StoreModule, ActionReducerMap } from '@ngrx/store';
import { State as AppState } from '../../state';
import * as NavMenuReducer from './nav-menu.reducer';
export { NavMenuReducer };

export interface State extends AppState {
  navMenuFeature: {
    navMenuReducer: NavMenuReducer.State;
  };
}

export const reducers: ActionReducerMap<any, any> = {
  navMenuReducer: NavMenuReducer.reducer,
};
