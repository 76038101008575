import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

import { UserAccount } from '@local/user-account';

@Component({
  selector: 'app-user-account-menu',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <button
      mat-icon-button
      matTooltip="your profile"
      [matMenuTriggerFor]="options"
      type="button"
    >
      <img [src]="userAccount.avatar" style="margin: auto;" />
    </button>

    <mat-menu #options>
      <a
        mat-menu-item
        [routerLink]="[
          '/person'!,
          userAccount.perspective!.personProfileId,
          'profile'
        ]"
        type="button"
      >
        <mat-icon>account_box</mat-icon>
        <span>Your profile</span>
      </a>

      <a
        mat-menu-item
        [routerLink]="['/organization', userAccount.perspective!.id, 'profile']"
        type="button"
      >
        <mat-icon>business</mat-icon>
        <span>Your organization</span>
      </a>

      <a
        mat-menu-item
        [routerLink]="[
          '/person',
          userAccount.perspective!.personProfileId,
          'settings'
        ]"
        type="button"
      >
        <mat-icon>settings</mat-icon>
        <span>Your settings</span>
      </a>

      <button mat-menu-item (click)="signout.emit()" type="button">
        <mat-icon>power_settings_new</mat-icon>
        <span>Sign out</span>
      </button>
    </mat-menu>
  `,
  styleUrls: ['./user-account-menu.component.scss'],
})
export class UserAccountMenuComponent {
  @Input('userAccount') set _userAccount(value: UserAccount) {
    this.userAccount = value;

    // switch (value.perspective.__typename) {
    //   case 'Organization':
    //     this.perspectiveURLRoot = '/organization';
    //     break;
    //   case 'Collaborative':
    //     this.perspectiveURLRoot = '/collaborative';
    //     break;
    //   default:
    //     this.perspectiveURLRoot = '/person';
    //     break;
    // }
  }

  userAccount: UserAccount;
  perspectiveURLRoot: '/person' | '/organization' | '/collaborative' =
    '/organization';

  @Output() signout = new EventEmitter<void>();
}
