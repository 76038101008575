import * as Actions from './workspace.actions';
import * as BeforePanelActions from './before-panel.actions';
import * as AfterPanelActions from './after-panel.actions';
import * as BottomPanelActions from './bottom-panel.actions';
export { Actions };

export interface State {
  width: number;
  height: number;
  beforePanelWidth: number;
  beforePanelHeight: number;
  afterPanelWidth: number;
  afterPanelHeight: number;
  bottomPanelWidth: number;
  bottomPanelHeight: number;
}

const initialState: State = {
  width: 0,
  height: 0,
  beforePanelWidth: 0,
  beforePanelHeight: 0,
  afterPanelWidth: 0,
  afterPanelHeight: 0,
  bottomPanelWidth: 0,
  bottomPanelHeight: 0,
};

export function reducer(
  state: State = initialState,
  action:
    | Actions.Actions
    | BeforePanelActions.Actions
    | AfterPanelActions.Actions
    | BottomPanelActions.Actions,
): State {
  switch (action.type) {
    case Actions.SET_DIMENSIONS:
      return {
        ...state,
        width: action.payload.width,
        height: action.payload.height,
      };

    case BeforePanelActions.SET_DIMENSIONS:
      return {
        ...state,
        width: action.payload.width + state.afterPanelWidth,
        height: action.payload.height + state.bottomPanelHeight,
        beforePanelWidth: action.payload.width,
        beforePanelHeight: action.payload.height,
      };

    case AfterPanelActions.SET_DIMENSIONS:
      return {
        ...state,
        width: action.payload.width + state.beforePanelWidth,
        height: action.payload.height + state.bottomPanelHeight,
        afterPanelWidth: action.payload.width,
        afterPanelHeight: action.payload.height,
      };

    case BottomPanelActions.SET_DIMENSIONS:
      return {
        ...state,
        width: action.payload.width,
        height: action.payload.height + state.afterPanelHeight,
        bottomPanelWidth: action.payload.width,
        bottomPanelHeight: action.payload.height,
      };

    default:
      return state;
  }
}
