import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectionStrategy,
} from '@angular/core';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { Store, select } from '@ngrx/store';
import { State } from '../../state';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-toolbox-container',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <span class="tools-container" [innerHTML]="tools$ | async"></span>
  `,
  styleUrls: ['./toolbox-container.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ToolboxContainerComponent implements OnInit {
  public tools$: Observable<SafeHtml | null>;

  constructor(private store: Store<State>, private sanitizer: DomSanitizer) {}

  ngOnInit() {
    this.tools$ = this.store.pipe(
      select('routerReducer', 'state', 'tools'),
      map(value => value && this.sanitizer.bypassSecurityTrustHtml(value)),
    );
  }
}
