import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { UserAccountService } from '@local/user-account';
import { Subscription } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-perspective-picker',
  template: `
    <button
      *ngFor="let organization of organizations"
      (click)="select(organization)"
    >
      {{ organization.identifier }}
    </button>
  `,
  styles: [
    `
      :host {
        width: 600px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PerspectivePickerComponent implements OnInit, OnDestroy {
  organizations: {
    id: string;
    identifier: string;
  }[] = [];

  private subscriptions: Subscription[] = [];

  constructor(
    private userAccountService: UserAccountService,
    private cdr: ChangeDetectorRef,
    private ref: MatDialogRef<PerspectivePickerComponent>,
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.userAccountService.userAccount$.subscribe(user => {
        this.cdr.markForCheck();

        if (!user) {
          this.organizations = [];
          return;
        }

        this.organizations = user.organizations;
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  select(organization: { id: string }) {
    this.ref.close(organization.id);
  }
}
