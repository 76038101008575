
    <a
      mat-button
      [routerLink]="link"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ __change_detection_hack__: link }"
      [matTooltip]="label"
      [matTooltipDisabled]="!(navButtonService.collapse$ | async)"
      matTooltipPosition="after"
      [matTooltipShowDelay]="0"
    >
      <mat-icon *ngIf="svgName" [svgIcon]="svgName"></mat-icon>
      <mat-icon *ngIf="iconName">{{ iconName }}</mat-icon>
      <span [innerHTML]="label$ | async"></span>
    </a>
  