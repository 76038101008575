import { Action } from '@ngrx/store';
import { Type } from '@angular/core';

export const LOAD_TOOLS = '[Toolbar Container] Load tools';

export class LoadTools implements Action {
  readonly type = LOAD_TOOLS;

  constructor(public payload: Type<any> | null) {}
}

export type Actions = LoadTools;
