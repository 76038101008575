import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'sw-notification',
  template: `<ng-snotify></ng-snotify>`,
  styleUrls: ['./notification.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NotificationComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
