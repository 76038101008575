import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrimaryToolbarComponent } from './primary/primary-toolbar.component';
import { ServiceWorkLogoComponent } from './primary/service-work-logo.component';
import { UserAccountMenuComponent } from './primary/user-account-menu/user-account-menu.component';
import { SecondaryToolbarComponent } from './secondary/secondary-toolbar.component';
import { BreadcrumbContainerComponent } from './secondary/breadcrumb-container/breadcrumb-container.component';
import { BreadcrumbRendererComponent } from './secondary/breadcrumb-container/breadcrumb-renderer.component';
import { SWIconsModule } from '@local/icons';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { reducers } from './state';
import { ToolboxContainerComponent } from './secondary/toolbox-container/toolbox-container.component';
import { UserAccountModule } from '@local/user-account';

@NgModule({
  declarations: [
    PrimaryToolbarComponent,
    ServiceWorkLogoComponent,
    UserAccountMenuComponent,
    SecondaryToolbarComponent,
    ToolboxContainerComponent,
    BreadcrumbContainerComponent,
    BreadcrumbRendererComponent,
  ],
  exports: [PrimaryToolbarComponent, SecondaryToolbarComponent],
  imports: [
    CommonModule,
    SWIconsModule,
    MatButtonModule,
    MatMenuModule,
    MatTooltipModule,
    RouterModule,
    StoreModule.forFeature('toolbarFeature', reducers),
    UserAccountModule,
  ],
})
export class ToolbarModule {}
