import {
  Component,
  OnInit,
  ChangeDetectorRef,
  OnDestroy,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { UserAccountService, UserAccount } from '@local/user-account';
import { withLatestFrom } from 'rxjs/operators';

@Component({
  // tslint:disable component-selector
  selector: '[app-primary-toolbar]',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <mat-icon class="sw-logo" svgIcon="sw:icon"></mat-icon>

    <span class="perspective-identifier">
      {{ userAccount?.perspective?.identifier }}
    </span>

    <div class="spacer"></div>

    <app-user-account-menu
      *ngIf="userAccount"
      [userAccount]="userAccount"
      (signout)="signout()"
      class="flex-center"
    ></app-user-account-menu>
  `,
  styleUrls: ['./primary-toolbar.component.scss'],
})
export class PrimaryToolbarComponent implements OnInit, OnDestroy {
  userAccount: UserAccount;

  private subscriptions: Subscription[] = [];

  constructor(
    private userAccountService: UserAccountService,
    private ref: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.userAccountService.getUserAccountOrSignin().subscribe(user => {
        this.userAccount = user;
        this.ref.markForCheck();
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  signout() {
    this.userAccountService.signout();
  }
}
