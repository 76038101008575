
    <app-nav-button
      link="/getting-started"
      iconName="child_friendly"
      label="Getting Started"
    >
    </app-nav-button>
    <!--
    <app-nav-button link="/calendar" iconName="event">
      Calendar
    </app-nav-button>
    -->
    <app-nav-button link="/contacts" iconName="people" label="Contacts">
    </app-nav-button>
  