import * as Actions from './bottom-panel.actions';
import { PanelState } from './panel.state';
export { Actions, PanelState as State };

const initialState: PanelState = {
  visible: true,
  width: 0,
  height: 0,
};

export function reducer(state: PanelState = initialState, action: Actions.Actions): PanelState {
  switch (action.type) {
    case Actions.TOGGLE_PANEL:
      return {
        ...state,
        visible: action.payload,
      };

    case Actions.SET_DIMENSIONS:
      return {
        ...state,
        height: action.payload.height,
        width: action.payload.width,
      };

    default:
      return state;
  }
}
