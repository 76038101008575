import { Action } from '@ngrx/store';
import { Type } from '@angular/core';

export const LOAD_BREADCRUMBS = '[Breadcrumb Container] Load breadcrumbs';

export class LoadBreadcrumbs implements Action {
  readonly type = LOAD_BREADCRUMBS;

  constructor(public payload: Type<any>[]) {}
}

export type Actions = LoadBreadcrumbs;
