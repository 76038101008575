import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  // tslint:disable component-selector
  selector: '[app-secondary-toolbar]',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <app-breadcrumb-container></app-breadcrumb-container>
    <div class="flex-bar"></div>
    <app-toolbox-container></app-toolbox-container>
  `,
  host: { class: 'sw-main-toolbar' },
  styleUrls: ['./secondary-toolbar.component.scss'],
})
export class SecondaryToolbarComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
