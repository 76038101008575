import * as Actions from './nav-menu.actions';
export { Actions };
import { Type } from '@angular/core';

export interface State {
  width: number;
  subMenu: string | null;
}

const initialState: State = {
  width: 208,
  subMenu: null,
};

export function reducer(state = initialState, action: Actions.Actions): State {
  switch (action.type) {
    case Actions.MENU_WIDTH:
      return {
        ...state,
        width: action.payload,
      };

    case Actions.LOAD_SUBMENU:
      return {
        ...state,
        subMenu: action.payload,
      };

    default:
      return state;
  }
}
