
    <mat-progress-bar
      *ngIf="('default' | swIsLoading | async)"
      mode="indeterminate"
      color="warn"
      style="position: absolute; top: 0; z-index: 5000;"
    >
    </mat-progress-bar>

    <header class="mat-elevation-z8">
      <mat-toolbar>
        <mat-toolbar-row app-primary-toolbar> </mat-toolbar-row>
        <mat-toolbar-row app-secondary-toolbar> </mat-toolbar-row>
      </mat-toolbar>
    </header>

    <mat-sidenav-container>
      <mat-sidenav mode="side" opened class="mat-elevation-z5 elevation-right">
        <app-nav-menu></app-nav-menu>
      </mat-sidenav>

      <mat-sidenav-content [style.margin-left]="drawerWidth">
        <main><router-outlet></router-outlet></main>
      </mat-sidenav-content>
    </mat-sidenav-container>

    <sw-notification></sw-notification>
  