import { Component, OnInit } from '@angular/core';
import { IsLoadingService } from '@service-work/is-loading';
// import { AngularFireAuth } from '@angular/fire/auth';
// import { AngularFirestore } from '@angular/fire/firestore';
import { UserAccountService } from '@local/user-account';
import {
  Router,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
} from '@angular/router';
import { filter, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { State } from './nav-menu/state';
// import { State } from './state';
// import { DocDeletionService } from '@local/firebase/state';

@Component({
  selector: 'app-root',
  template: `
    <mat-progress-bar
      *ngIf="('default' | swIsLoading | async)"
      mode="indeterminate"
      color="warn"
      style="position: absolute; top: 0; z-index: 5000;"
    >
    </mat-progress-bar>

    <header class="mat-elevation-z8">
      <mat-toolbar>
        <mat-toolbar-row app-primary-toolbar> </mat-toolbar-row>
        <mat-toolbar-row app-secondary-toolbar> </mat-toolbar-row>
      </mat-toolbar>
    </header>

    <mat-sidenav-container>
      <mat-sidenav mode="side" opened class="mat-elevation-z5 elevation-right">
        <app-nav-menu></app-nav-menu>
      </mat-sidenav>

      <mat-sidenav-content [style.margin-left]="drawerWidth">
        <main><router-outlet></router-outlet></main>
      </mat-sidenav-content>
    </mat-sidenav-container>

    <sw-notification></sw-notification>
  `,
})
export class AppComponent implements OnInit {
  drawerWidth = '208px';

  constructor(
    public isLoadingService: IsLoadingService,
    public userAccountService: UserAccountService,
    private store: Store<State>,
    // public fauth: AngularFireAuth,
    // public fstore: AngularFirestore,
    private router: Router,
  ) {}

  ngOnInit() {
    this.userAccountService.getUserAccountOrSignin().subscribe();

    this.router.events
      .pipe(
        filter(
          event =>
            event instanceof NavigationStart ||
            event instanceof NavigationEnd ||
            event instanceof NavigationCancel ||
            event instanceof NavigationError,
        ),
        // withLatestFrom(this.store.select('routerReducer')),
      )
      .subscribe(event => {
        // If it's the start of navigation, `add()` a loading indicator
        if (event instanceof NavigationStart) {
          this.isLoadingService.add();
          return;
        }

        // this.store.dispatch(
        //   new SafeRouterReducer.Actions.LoadRoute(routerReducerState),
        // );

        // Else navigation has ended, so `remove()` a loading indicator
        this.isLoadingService.remove();
      });

    this.store
      .select('navMenuFeature', 'navMenuReducer', 'width')
      .subscribe(width => {
        this.drawerWidth = `${width}px`;
      });
  }
}
