import moment from 'moment-timezone';
import gql from 'graphql-tag';
import { GetUserProfileQuery } from './user-account.model.gql.types';

export const userAccountPoll = gql`
  query PollUserProfile($userAccountId: uuid!) {
    UserProfile(where: { id: { _eq: $userAccountId } }) {
      id
      updatedAt

      userSensitiveProfile {
        userProfileId

        primaryUserEmailAddress {
          id
          updatedAt
        }

        userEmailAddresses {
          id
          updatedAt
        }

        updatedAt
      }

      organizationMembers {
        organization {
          id
          name

          personProfiles(
            where: {
              userProfiles: { userProfileId: { _eq: $userAccountId } }
            }
          ) {
            id
          }
        }

        privileges
        updatedAt
      }
    }
  }
`;

export const userAccountQuery = gql`
  query GetUserProfile($userAccountId: uuid!) {
    UserProfile(where: { id: { _eq: $userAccountId } }) {
      id
      nickname
      firstName
      firstNamePronunciation
      middleName
      middleNamePronunciation
      lastName
      lastNamePronunciation
      photoUrl
      updatedAt

      userSensitiveProfile {
        userProfileId

        primaryUserEmailAddress {
          id
          label
          emailAddress
        }

        userEmailAddresses {
          id
          label
          emailAddress
        }

        updatedAt
      }

      organizationMembers {
        organization {
          id
          name

          personProfiles(
            where: {
              userProfiles: { userProfileId: { _eq: $userAccountId } }
            }
          ) {
            id
          }
        }

        privileges
        updatedAt
      }
    }
  }
`;

export class UserEmailAddress {
  __typename: 'UserEmailAddress' = 'UserEmailAddress';
  id: string;
  label?: string;
  emailAddress: string;

  constructor(args: { id: string; label?: string; emailAddress: string }) {
    Object.assign(this, args);
  }
}

export interface UserAccountPerspective {
  id: string;
  identifier: string;

  /**
   * - admin
   * - manager
   * - send_email
   * - edit_sensitive
   * - edit_public
   * - view_sensitive
   * - view_public
   */

  privileges: string[];
  personProfileId: string;
}

export type Privileges =
  | 'admin'
  | 'manager'
  | 'send_email'
  | 'edit_sensitive'
  | 'edit_public'
  | 'view_sensitive'
  | 'view_public';

export class UserAccount {
  __typename: 'UserAccount' = 'UserAccount';

  id: string;
  identifier: string;
  nickname: string | null;
  firstName: string;
  firstNamePronunciation: string | null;
  middleName: string | null;
  middleNamePronunciation: string | null;
  lastName: string;
  lastNamePronunciation: string | null;
  photoUrl: string | null;
  avatar: string;

  primaryEmailAddress: UserEmailAddress;
  emailAddresses: UserEmailAddress[] = [];

  perspective?: UserAccountPerspective;

  organizations: UserAccountPerspective[] = [];

  constructor(args: GetUserProfileQuery['UserProfile'][0]) {
    Object.assign(this, args);

    this.primaryEmailAddress = new UserEmailAddress(this.primaryEmailAddress);
    this.emailAddresses = this.emailAddresses.map(
      arg => new UserEmailAddress(arg),
    );

    this.organizations = args.organizationMembers.map(member => {
      return {
        id: member.organization.id,
        identifier: member.organization.name,
        privileges: member.privileges || [],
        personProfileId: member.organization.personProfiles[0].id,
      };
    });

    this.identifier = [
      this.nickname || this.firstName,
      this.middleName,
      this.lastName,
    ]
      .filter(val => !!val)
      .join(' ');

    this.avatar = this.photoUrl || '/assets/person/avatar.jpg';
  }

  can(can: Privileges) {
    if (!this.perspective) return false;

    const { privileges } = this.perspective;

    switch (can) {
      case 'edit_sensitive': {
        return privileges.some(
          p => p === 'admin' || p === 'manager' || p === 'edit_sensitive',
        );
      }
      case 'edit_public': {
        return privileges.some(
          p => p === 'admin' || p === 'manager' || p === 'edit_public',
        );
      }
      case 'manager': {
        return privileges.some(p => p === 'admin' || p === 'manager');
      }
      default: {
        throw new Error(
          `UserAccount#can() called with invalid argument ${can}`,
        );
      }
    }
  }
}
