import {
  Component,
  ChangeDetectionStrategy,
  OnDestroy,
  ChangeDetectorRef,
  AfterViewInit,
  forwardRef,
} from '@angular/core';

import { Store } from '@ngrx/store';
import { State, NavMenuReducer } from './state';
import { Subscription, BehaviorSubject } from 'rxjs';
import { NavButtonService } from './nav-button/nav-button.component';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-nav-menu',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div
      class="nav-wrapper mainmenu mat-elevation-z5 elevation-right"
      [ngClass]="{ collapse: collapse$ | async }"
    >
      <nav app-nav-main-menu></nav>
    </div>

    <div *ngIf="subMenu" class="nav-wrapper submenu">
      <nav [innerHTML]="subMenu"></nav>
    </div>
  `,
  styleUrls: ['./nav-menu.component.scss'],
  providers: [
    {
      provide: NavButtonService,
      useExisting: forwardRef(() => NavMenuComponent),
    },
  ],
})
export class NavMenuComponent
  implements AfterViewInit, OnDestroy, NavButtonService {
  subMenu: SafeHtml | null = null;

  collapse$ = new BehaviorSubject(false);

  private subscriptions: Subscription[] = [];

  constructor(
    private store: Store<State>,
    private sanitizer: DomSanitizer,
    private ref: ChangeDetectorRef,
  ) {}

  ngAfterViewInit() {
    this.subscriptions.push(
      this.store
        .select('routerReducer', 'state', 'componentMenu')
        .subscribe(menu => {
          if (menu) {
            this.subMenu = this.sanitizer.bypassSecurityTrustHtml(menu);
            this.collapse$.next(true);
            this.store.dispatch(new NavMenuReducer.Actions.MenuWidth(280));
          } else {
            this.subMenu = null;
            this.collapse$.next(false);
            this.store.dispatch(new NavMenuReducer.Actions.MenuWidth(208));
          }

          this.ref.markForCheck();
        }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
