import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  // {
  //   path: 'calendar',
  //   loadChildren: './calendar/calendar.module#CalendarModule',
  // },
  {
    path: 'contact-list',
    loadChildren: './contact-list/contact-list.module#ContactListModule',
  },
  {
    path: 'contacts',
    loadChildren: './contacts/contacts.module#ContactsModule',
  },
  // {
  //   path: 'email',
  //   loadChildren: './email/email.module#EmailModule',
  // },
  {
    path: 'getting-started',
    loadChildren:
      './getting-started/getting-started.module#GettingStartedModule',
  },
  // {
  //   path: 'opportunity',
  //   loadChildren: './opportunity/opportunity.module#OpportunityModule',
  // },
  {
    path: 'organization',
    loadChildren: './organization/organization.module#OrganizationModule',
  },
  {
    path: 'person',
    loadChildren: './person/person.module#PersonModule',
  },
  // {
  //   path: 'registration',
  //   loadChildren: './registration/registration.module#RegistrationModule',
  // },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'getting-started',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { initialNavigation: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
