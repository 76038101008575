import * as Actions from './toolbar.actions';
export { Actions };
import { Type } from '@angular/core';

export interface State {
  tools: Type<any> | null;
}

const initialState: State = {
  tools: null,
};

export function reducer(state = initialState, action: Actions.Actions): State {
  switch (action.type) {
    case Actions.LOAD_TOOLS:
      return {
        ...state,
        tools: action.payload,
      };

    default:
      return state;
  }
}
