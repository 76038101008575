import { Injectable, Inject } from '@angular/core';
import { ENVIRONMENT, IEnvironment } from '@local/shared-environment';

declare const gapi: any;

@Injectable({
  providedIn: 'root',
})
export class ProviderService {
  private gapiAuth:
    | {
        signIn: (options: any) => Promise<any>;
        signOut: () => Promise<void>;
      }
    | undefined;
  private gapiPromise: Promise<any> | undefined;

  private fbsdk: FBSDK | undefined;
  private fbPromise: Promise<any> | undefined;

  constructor(@Inject(ENVIRONMENT) private environment: IEnvironment) {
    // Chrome lets us load these on demand, but firefox will block the popup
    // when loaded on demand. If we preload firefox won't block the popup.
    this.googleSDK();
    this.facebookSDK();
  }

  async signinGoogle() {
    const googleUser = await (await this.googleSDK()).signIn({});

    if (googleUser.error) return null;

    const profile = googleUser.getBasicProfile();

    return {
      type: 'GOOGLE',
      token: googleUser.getAuthResponse().id_token as string,
      uid: profile.getId() as string,
      firstName: profile.getGivenName() as string,
      lastName: profile.getFamilyName() as string,
      photoUrl: profile.getImageUrl() as string,
      emailAddress: profile.getEmail() as string,
    };
  }

  async signinFacebook() {
    const response: FB.LoginStatusResponse = await new Promise(async res => {
      (await this.facebookSDK()).login(res, { scope: 'email' });
    });

    if (!(response && response.status === 'connected')) return null;

    const data: any = await new Promise(async res => {
      (await this.facebookSDK()).api(
        '/me',
        'get',
        {
          fields: 'id,first_name,last_name,picture.type(large){url},email',
          access_token: response.authResponse!.accessToken,
        },
        res,
      );
    });

    if (!(data && data.id)) return null;

    return {
      type: 'FACEBOOK',
      token: response.authResponse!.accessToken,
      uid: data.id,
      firstName: data.first_name,
      lastName: data.last_name,
      photoUrl: data.picture.data.url,
      emailAddress: data.email,
    };
  }

  private async googleSDK() {
    if (this.gapiAuth) return this.gapiAuth;

    if (!this.gapiPromise) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.defer = true;
      script.src = 'https://apis.google.com/js/api.js?onload=gapiClientLoaded';

      this.gapiPromise = new Promise<void>((res, rej) => {
        (window as any)['gapiClientLoaded'] = () => res();
        script.onerror = rej;
      })
        .then(() => new Promise(res => gapi.load('auth2', res)))
        .then(() =>
          gapi.auth2
            .init({
              clientId: this.environment.google!.clientId,
            })
            .then(),
        )
        .then(() => (this.gapiAuth = gapi.auth2.getAuthInstance()));

      document.body.appendChild(script);
    }

    await this.gapiPromise;

    return this.gapiAuth!;
  }

  private async facebookSDK() {
    if (this.fbsdk) return this.fbsdk;

    if (!this.fbPromise) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.id = 'facebook-jssdk';
      script.src = 'https://connect.facebook.net/en_US/sdk.js';

      this.fbPromise = new Promise((res, rej) => {
        (window as any).fbAsyncInit = res;
        script.onerror = rej;
      })
        .then(() => {
          FB.init({
            appId: this.environment.facebookShared.appId,
            cookie: true,
            xfbml: true,
            version: 'v3.2',
          });
        })
        .then(() => (this.fbsdk = FB));

      document.body.appendChild(script);
    }

    await this.fbPromise;

    return this.fbsdk!;
  }
}
