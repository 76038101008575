import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  Injectable,
} from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseComponent } from '@local/utilities/base-component';

@Injectable({
  providedIn: 'root',
})
export class NavButtonService {
  collapse$ = new BehaviorSubject(false);
}

@Component({
  selector: 'app-nav-button',
  changeDetection: ChangeDetectionStrategy.OnPush,
  // see https://github.com/angular/angular/issues/18469
  // for explaination on '__change_detection_hack__'
  template: `
    <a
      mat-button
      [routerLink]="link"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ __change_detection_hack__: link }"
      [matTooltip]="label"
      [matTooltipDisabled]="!(navButtonService.collapse$ | async)"
      matTooltipPosition="after"
      [matTooltipShowDelay]="0"
    >
      <mat-icon *ngIf="svgName" [svgIcon]="svgName"></mat-icon>
      <mat-icon *ngIf="iconName">{{ iconName }}</mat-icon>
      <span [innerHTML]="label$ | async"></span>
    </a>
  `,
  styleUrls: ['./nav-button.component.scss'],
})
export class NavButtonComponent extends BaseComponent {
  @Input() svgName: string;
  @Input() iconName: string;
  @Input() link: string | Array<any>;
  @Input() label: string;

  label$ = combineLatest([
    this.navButtonService.collapse$,
    this.ngChanges$.pipe(map(() => this.label)),
  ]).pipe(map(([collapse, label]) => (collapse ? '' : label)));

  constructor(public navButtonService: NavButtonService) {
    super();
  }
}
