
    <div
      class="nav-wrapper mainmenu mat-elevation-z5 elevation-right"
      [ngClass]="{ collapse: collapse$ | async }"
    >
      <nav app-nav-main-menu></nav>
    </div>

    <div *ngIf="subMenu" class="nav-wrapper submenu">
      <nav [innerHTML]="subMenu"></nav>
    </div>
  