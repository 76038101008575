import { ActionReducerMap } from '@ngrx/store';
import * as WorkspaceReducer from './workspace.reducer';
import * as BeforePanelReducer from './before-panel.reducer';
import * as AfterPanelReducer from './after-panel.reducer';
import * as BottomPanelReducer from './bottom-panel.reducer';
export {
  WorkspaceReducer,
  BeforePanelReducer,
  AfterPanelReducer,
  BottomPanelReducer,
};
// import { State as SharedState } from '@local/utilities';

export interface WorkspaceState {
  workspaceReducer: WorkspaceReducer.State;
  beforePanelReducer: BeforePanelReducer.State;
  afterPanelReducer: AfterPanelReducer.State;
  bottomPanelReducer: BottomPanelReducer.State;
}

export const reducers: ActionReducerMap<WorkspaceState, any> = {
  workspaceReducer: WorkspaceReducer.reducer,
  beforePanelReducer: BeforePanelReducer.reducer,
  afterPanelReducer: AfterPanelReducer.reducer,
  bottomPanelReducer: BottomPanelReducer.reducer,
};

export interface State {
  workspaceFeature: WorkspaceState;
}
