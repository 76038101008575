import { Injectable, Inject } from '@angular/core';

import { UserAccountService, UserAccount } from '@local/user-account';
import { tap, filter, switchMap, map, shareReplay } from 'rxjs/operators';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { ENVIRONMENT, IEnvironment } from '@local/shared-environment';
import { Apollo } from '@local/graphql';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PerspectivePickerComponent } from './perspective-picker.component';

@Injectable()
export class StaffUserAccountService extends UserAccountService {
  userAccount$ = this.userAccount$.pipe(
    switchMap(user =>
      !user
        ? of(null)
        : this.perspectiveId$.pipe(
            map(id => {
              user.perspective = user.organizations.find(org => org.id === id);
              return user;
            }),
          ),
    ),
    shareReplay(1),
  ) as Observable<UserAccount | null>;

  private perspectiveId$ = new BehaviorSubject<string | null>(null);
  private dialogRef?: MatDialogRef<PerspectivePickerComponent>;

  constructor(
    @Inject(DOCUMENT) protected document: Document,
    @Inject(ENVIRONMENT) protected environment: IEnvironment,
    protected apollo: Apollo,
    protected dialog: MatDialog,
  ) {
    super(document, environment, apollo);
  }

  getUserAccountOrSignin() {
    return super.getUserAccountOrSignin().pipe(
      tap(user => {
        if (user.organizations.length === 0) {
          this.document.location.href =
            this.environment.uris.staffMarketing + `/organization/signup`;
        } else if (!user.perspective) {
          if (this.dialogRef) return;
          else if (user.organizations.length === 1) {
            this.perspectiveId$.next(user.organizations[0].id);
            return;
          }

          this.dialogRef = this.dialog.open(PerspectivePickerComponent, {
            disableClose: true,
          });

          this.dialogRef.afterClosed().subscribe(perspectiveId => {
            this.dialogRef = undefined;
            this.perspectiveId$.next(perspectiveId);
          });
        }
      }),
      filter(user => !!user.perspective),
      shareReplay(1),
    );
  }
}
