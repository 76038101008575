import { Injectable, Inject } from '@angular/core';
import { Observable, BehaviorSubject, of } from 'rxjs';
import {
  map,
  tap,
  filter,
  distinctUntilChanged,
  skip,
  switchMap,
  shareReplay,
} from 'rxjs/operators';

import { ENVIRONMENT, IEnvironment } from '@local/shared-environment';
import { DOCUMENT } from '@angular/common';
import { CookieService } from '@local/cookie';
import { Apollo } from '@local/graphql';
import {
  UserAccount,
  userAccountQuery,
  UserAccountPerspective,
  userAccountPoll,
} from './user-account.model';
import {
  GetUserProfileQuery,
  GetUserProfileQueryVariables,
} from './user-account.model.gql.types';
import isTruthy from '@local/utilities/isTruthy';

@Injectable({ providedIn: 'root' })
export class UserAccountService {
  userAccount: UserAccount | null = null;

  userAccount$ = this.apollo
    .pollingQuery<GetUserProfileQuery, GetUserProfileQueryVariables>({
      freq: 30000,
      pollingQuery: this.apollo.watchQuery({
        query: userAccountPoll,
        variables: {
          userAccountId: 'd851f381-71fd-4f2d-9648-3ccb48c22e1a',
        },
      }),
      query: this.apollo.watchQuery({
        query: userAccountQuery,
        variables: {
          userAccountId: 'd851f381-71fd-4f2d-9648-3ccb48c22e1a',
        },
      }),
    })
    .pipe(
      map(
        ({ data }) =>
          (data &&
            (data.UserProfile[0] && new UserAccount(data.UserProfile[0]))) ||
          null,
      ),
      tap(user => (this.userAccount = user)),
      shareReplay(1),
    ) as Observable<UserAccount | null>;

  constructor(
    @Inject(DOCUMENT) protected document: Document,
    @Inject(ENVIRONMENT) protected environment: IEnvironment,
    protected apollo: Apollo,
  ) {
    // this.cookieService
    //   .cookieGet('csst')
    //   .pipe(
    //     skip(1),
    //     filter(cookie => !cookie),
    //   )
    //   .subscribe(() => this.fauth.auth.signOut());
  }

  /*
   * Must use method calls rather than properties to correctly
   * handle subclassing.
   */

  getUserAccountOrSignin(): Observable<UserAccount> {
    return this.userAccount$.pipe(
      // tap(user => {
      //   if (user) return;

      //   this.document.location.href =
      //     `${this.environment.uris.accountClient}/signin` +
      //     `?redirect=${this.document.location.href}`;
      // }),
      filter(isTruthy),
    );
  }

  getUserPerspectiveOrSignin(): Observable<UserAccountPerspective> {
    return this.getUserAccountOrSignin().pipe(
      map(user => user.perspective),
      filter(isTruthy),
    );
  }

  getAuthStatus() {
    return this.userAccount$.pipe(
      map(user => (user ? 'SIGNED_IN' : 'SIGNED_OUT')),
      distinctUntilChanged(),
    );
  }

  async signout() {
    // const results = await this.ffunc
    //   .exec('userAccount-signout', null)
    //   .toPromise();
    // if (results.success) {
    //   await this.fauth.auth.signOut();
    // }
  }
}
