import { Component, Type } from '@angular/core';
import { RouterStateSerializer } from '@ngrx/router-store';
import { Params, RouterStateSnapshot } from '@angular/router';

export interface State {
  url: string;
  params: Params;
  queryParams: Params;
  breadcrumbs: string[];
  componentMenu: string | null;
  tools: string | null;
}

export class CustomRouterStateSerializer
  implements RouterStateSerializer<State> {
  serialize(routerState: RouterStateSnapshot): State {
    let route = routerState.root;
    const breadcrumbs: string[] = [];
    let componentMenu: string | null = null;
    let tools: string | null = null;
    let params: any = {};

    /**
     * Note:
     * The "data" property of parent routes is merged into the child
     * route's "data" property.
     */

    while (route.firstChild) {
      route = route.firstChild;

      if (route.outlet === 'primary') {
        if (route.data.breadcrumb) {
          breadcrumbs.push(route.data.breadcrumb);
        }

        if (route.data.menu) {
          componentMenu = route.data.menu;
        }

        if (route.data.tools) {
          tools = route.data.tools;
        }

        params = {
          ...params,
          ...route.params,
        };
      }
    }

    const { url } = routerState;
    const queryParams = routerState.root.queryParams;

    // Only return an object including the URL, params and query params
    // instead of the entire snapshot
    return { url, params, queryParams, breadcrumbs, componentMenu, tools };
  }
}
