import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { SWIconsModule } from '@local/icons';

import { NavButtonComponent } from './nav-button.component';
import { MatTooltipModule } from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    SWIconsModule,
    MatButtonModule,
    MatTooltipModule,
    RouterModule,
  ],
  declarations: [NavButtonComponent],
  exports: [NavButtonComponent],
  providers: [],
})
export class NavButtonModule {}
