import * as Actions from './breadcrumb.actions';
export { Actions };
import { Type } from '@angular/core';

export interface State {
  breadcrumbs: Type<any>[];
}

const initialState: State = {
  breadcrumbs: [],
};

export function reducer(state = initialState, action: Actions.Actions): State {
  switch (action.type) {
    case Actions.LOAD_BREADCRUMBS:
      return {
        ...state,
        breadcrumbs: action.payload,
      };

    default:
      return state;
  }
}
