import { Action } from '@ngrx/store';
import { Type } from '@angular/core';

export const MENU_WIDTH = '[NavMenu] Menu width';
export const LOAD_SUBMENU = '[NavMenu] Load submenu';

export class MenuWidth implements Action {
  readonly type = MENU_WIDTH;

  constructor(public payload: number) {}
}

export class LoadSubmenu implements Action {
  readonly type = LOAD_SUBMENU;

  constructor(public payload: string | null) {}
}

export type Actions = MenuWidth | LoadSubmenu;
