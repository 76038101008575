import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularSplitModule } from 'angular-split';

// Configure ngrx feature store
import { StoreModule } from '@ngrx/store';
import * as WorkspaceState from './state';
export { WorkspaceState };

import {
  WorkspaceComponent,
  ViewportPanelDirective,
} from './workspace.component';

@NgModule({
  imports: [
    AngularSplitModule,
    CommonModule,
    StoreModule.forFeature('workspaceFeature', WorkspaceState.reducers),
  ],
  declarations: [WorkspaceComponent, ViewportPanelDirective],
  exports: [WorkspaceComponent, ViewportPanelDirective],
  providers: [],
})
export class WorkspaceModule {}
