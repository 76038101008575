import {
  Component,
  Input,
  ChangeDetectionStrategy,
  OnChanges,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-breadcrumb-renderer',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <mat-icon *ngIf="index != 0" class="divider">chevron_right</mat-icon>
    <span #breadcrumbContainer class="breadcrumb" [innerHTML]="html"></span>
  `,
})
export class BreadcrumbRendererComponent implements OnChanges {
  @Input() breadcrumb: string;
  @Input() index: number;

  public html: SafeHtml;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnChanges() {
    this.html = this.sanitizer.bypassSecurityTrustHtml(this.breadcrumb);
  }
}
