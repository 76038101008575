
    <button
      mat-icon-button
      matTooltip="your profile"
      [matMenuTriggerFor]="options"
      type="button"
    >
      <img [src]="userAccount.avatar" style="margin: auto;" />
    </button>

    <mat-menu #options>
      <a
        mat-menu-item
        [routerLink]="[
          '/person'!,
          userAccount.perspective!.personProfileId,
          'profile'
        ]"
        type="button"
      >
        <mat-icon>account_box</mat-icon>
        <span>Your profile</span>
      </a>

      <a
        mat-menu-item
        [routerLink]="['/organization', userAccount.perspective!.id, 'profile']"
        type="button"
      >
        <mat-icon>business</mat-icon>
        <span>Your organization</span>
      </a>

      <a
        mat-menu-item
        [routerLink]="[
          '/person',
          userAccount.perspective!.personProfileId,
          'settings'
        ]"
        type="button"
      >
        <mat-icon>settings</mat-icon>
        <span>Your settings</span>
      </a>

      <button mat-menu-item (click)="signout.emit()" type="button">
        <mat-icon>power_settings_new</mat-icon>
        <span>Sign out</span>
      </button>
    </mat-menu>
  