import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { SWIconsModule } from '@local/icons';
import { NavButtonModule } from './nav-button/nav-button.module';

import { NavMenuComponent } from './nav-menu.component';
import { StoreModule } from '@ngrx/store';
import { reducers } from './state';
import { NavMainMenuComponent } from './nav-main-menu.component';

@NgModule({
  imports: [
    StoreModule.forFeature('navMenuFeature', reducers),
    CommonModule,
    MatTabsModule,
    SWIconsModule,
    MatButtonModule,
    NavButtonModule,
  ],
  declarations: [NavMenuComponent, NavMainMenuComponent],
  exports: [NavMenuComponent],
  entryComponents: [NavMainMenuComponent],
})
export class NavMenuModule {}
