import { Subscription, Subject, Observable } from 'rxjs';

import { SimpleChanges } from './simple-changes-patch';
import { filter, shareReplay, takeUntil } from 'rxjs/operators';

export abstract class NgLifecycle {}

export class OnChanges<T = SimpleChanges> extends NgLifecycle {
  constructor(public changes: T) {
    super();
  }
}

export class OnInit extends NgLifecycle {}

export class AfterContentInit extends NgLifecycle {}

export class AfterContentChecked extends NgLifecycle {}

export class AfterViewInit extends NgLifecycle {}

export class AfterViewChecked extends NgLifecycle {}

export class OnDestroy extends NgLifecycle {}

export abstract class BaseComponent {
  ngLifecycle$ = new Subject<NgLifecycle>();
  ngDestroyed$ = this.ngLifecycle$.pipe(
    filter(event => event instanceof OnDestroy),
  );
  ngChanges$ = ((this.ngLifecycle$ as unknown) as Observable<OnChanges>).pipe(
    filter(event => event instanceof OnChanges),
    shareReplay(1),
    takeUntil(this.ngDestroyed$),
  );

  protected subscriptions: Subscription[] = [];

  constructor() {
    this.ngChanges$.subscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((this as any).swOnChanges) (this as any).swOnChanges(changes);
    this.ngLifecycle$.next(new OnChanges(changes));
  }

  ngOnInit() {
    if ((this as any).swOnInit) (this as any).swOnInit();
    this.ngLifecycle$.next(new OnInit());
  }

  ngAfterContentInit() {
    if ((this as any).swAfterContentInit) (this as any).swAfterContentInit();
    this.ngLifecycle$.next(new AfterContentInit());
  }

  ngAfterContentChecked() {
    if ((this as any).swAfterContentChecked) {
      (this as any).swAfterContentChecked();
    }
    this.ngLifecycle$.next(new AfterContentChecked());
  }

  ngAfterViewInit() {
    if ((this as any).swAfterViewInit) (this as any).swAfterViewInit();
    this.ngLifecycle$.next(new AfterViewInit());
  }

  ngAfterViewChecked() {
    if ((this as any).swAfterViewChecked) (this as any).swAfterViewChecked();
    this.ngLifecycle$.next(new AfterViewChecked());
  }

  ngOnDestroy() {
    if ((this as any).swOnDestroy) (this as any).swOnDestroy();
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.ngLifecycle$.next(new OnDestroy());
    this.ngLifecycle$.complete();
  }
}
