import { Action } from '@ngrx/store';

export const TOGGLE_PANEL = '[Workspace][After Panel] Toggle';
export const SET_DIMENSIONS = '[Workspace][After Panel] Set dimensions';

export class TogglePanel implements Action {
  readonly type = TOGGLE_PANEL;

  constructor(public payload: boolean) {}
}

export class SetDimensions implements Action {
  readonly type = SET_DIMENSIONS;

  constructor(public payload: { width: number; height: number }) {}
}

export type Actions = TogglePanel | SetDimensions;
