import { Action } from '@ngrx/store';

export const SET_DIMENSIONS = '[Workspace] Set dimensions';

export class SetDimensions implements Action {
  readonly type = SET_DIMENSIONS;

  constructor(public payload: { width: number; height: number }) {}
}

export type Actions = SetDimensions;
