import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewEncapsulation,
} from '@angular/core';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { State } from '../../state';

@Component({
  selector: 'app-breadcrumb-container',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <app-breadcrumb-renderer
      *ngFor="let breadcrumb of (breadcrumbs | async); let i = index"
      [breadcrumb]="breadcrumb"
      [index]="i"
      class="renderer"
    >
    </app-breadcrumb-renderer>
  `,
  styleUrls: ['./breadcrumb-container.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BreadcrumbContainerComponent implements OnInit {
  public breadcrumbs: Observable<string[]>;

  constructor(private store: Store<State>) {}

  ngOnInit() {
    this.breadcrumbs = this.store.pipe(
      select('routerReducer', 'state', 'breadcrumbs'),
    );
  }
}
