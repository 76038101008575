
    <mat-icon class="sw-logo" svgIcon="sw:icon"></mat-icon>

    <span class="perspective-identifier">
      {{ userAccount?.perspective?.identifier }}
    </span>

    <div class="spacer"></div>

    <app-user-account-menu
      *ngIf="userAccount"
      [userAccount]="userAccount"
      (signout)="signout()"
      class="flex-center"
    ></app-user-account-menu>
  