import {
  BrowserModule,
  BrowserTransferStateModule,
} from '@angular/platform-browser';
import { NgModule, Provider } from '@angular/core';

// Angular
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { TransferHttpCacheModule } from '@nguniversal/common';

// State
import { StoreModule } from '@ngrx/store';
import {
  StoreRouterConnectingModule,
  NavigationActionTiming,
} from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { reducers, metaReducers, CustomRouterStateSerializer } from './state';
import { EffectsModule } from '@ngrx/effects';

// Material
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import {
  MatTooltipDefaultOptions,
  MAT_TOOLTIP_DEFAULT_OPTIONS,
} from '@angular/material/tooltip';
import { MatMomentDateModule } from '@angular/material-moment-adapter';

// Components
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PerspectivePickerComponent } from './perspective-picker.component';

// Local
import { ToolbarModule } from './toolbar/toolbar.module';
import { NavMenuModule } from './nav-menu/nav-menu.module';
import { environment } from '../environments/environment';
import { NotificationModule } from '@local/notification';
import { UserAccountModule, UserAccountService } from '@local/user-account';
import { WorkspaceModule } from './workspace/workspace.module';
import { StaffUserAccountService } from './staff-user-account.service';
import { ENVIRONMENT, REDUCER_TOKEN } from '@local/shared-environment';

// Other
import { SkyhookDndModule } from '@angular-skyhook/core';
import {
  SkyhookMultiBackendModule,
  createDefaultMultiBackend,
} from '@angular-skyhook/multi-backend';
import { AngularSplitModule } from 'angular-split';
import { NgxMapboxGLModule, MAPBOX_API_KEY } from 'ngx-mapbox-gl';
import { IsLoadingModule } from '@service-work/is-loading';

import { SWGraphQLModule } from '@local/graphql';
import { AngularFireModule } from '@angular/fire';
import {
  AngularFireFunctionsModule,
  FUNCTIONS_ORIGIN,
} from '@angular/fire/functions';

import { MAT_CHIPS_DEFAULT_OPTIONS } from '@angular/material';

import { COMMA, ENTER } from '@angular/cdk/keycodes';

export const tooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 500,
  hideDelay: 0,
  touchendHideDelay: 1500,
};

if (!environment.production) {
  console.log('environment', environment);
}

@NgModule({
  declarations: [AppComponent, PerspectivePickerComponent],
  imports: [
    AngularSplitModule.forRoot(),
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireFunctionsModule,
    BrowserModule.withServerTransition({ appId: 'service-work-coordination' }),
    BrowserTransferStateModule,
    BrowserAnimationsModule,
    HttpClientModule,
    IsLoadingModule,
    MatDialogModule,
    MatMomentDateModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatToolbarModule,
    NavMenuModule,
    NotificationModule,
    NgxMapboxGLModule.withConfig({
      // for some reason this isn't working, so we're also manually setting
      // the api key via a provider below (which is working)
      accessToken: environment.mapboxShared.accessToken,
    }),
    TransferHttpCacheModule,
    ToolbarModule,
    SkyhookMultiBackendModule,
    SkyhookDndModule.forRoot({ backendFactory: createDefaultMultiBackend }),
    StoreModule.forRoot(REDUCER_TOKEN, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: !environment.production,
        strictActionImmutability: !environment.production,
      },
    }),
    StoreRouterConnectingModule.forRoot({
      serializer: CustomRouterStateSerializer,
      navigationActionTiming: NavigationActionTiming.PostActivation,
    }),
    SWGraphQLModule,
    EffectsModule.forRoot([]),
    UserAccountModule,
    WorkspaceModule,
    !environment.production
      ? StoreDevtoolsModule.instrument({ maxAge: 100 })
      : [],
  ],
  providers: [
    {
      provide: FUNCTIONS_ORIGIN,
      useValue: environment.uris.functions.startsWith('http://localhost')
        ? environment.uris.functions
        : null,
    },
    { provide: ENVIRONMENT, useValue: environment },
    { provide: UserAccountService, useClass: StaffUserAccountService },
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: tooltipDefaults },
    { provide: REDUCER_TOKEN, useValue: reducers },
    // manually setting ngx-mapbox-gl accessToken because setting it above
    // isn't working
    { provide: MAPBOX_API_KEY, useValue: environment.mapboxShared.accessToken },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'fill' },
    },
    {
      provide: MAT_CHIPS_DEFAULT_OPTIONS,
      useValue: {
        separatorKeyCodes: [ENTER, COMMA],
      },
    },
  ],
  bootstrap: [AppComponent],
  entryComponents: [PerspectivePickerComponent],
})
export class AppModule {}
