import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'nav[app-nav-main-menu]',
  template: `
    <app-nav-button
      link="/getting-started"
      iconName="child_friendly"
      label="Getting Started"
    >
    </app-nav-button>
    <!--
    <app-nav-button link="/calendar" iconName="event">
      Calendar
    </app-nav-button>
    -->
    <app-nav-button link="/contacts" iconName="people" label="Contacts">
    </app-nav-button>
  `,
  styles: [''],
})
export class NavMainMenuComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
