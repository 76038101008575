// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { sharedEnvironment, IEnvironment } from '@local/shared-environment';

export const environment: IEnvironment = {
  ...sharedEnvironment,
  production: false,
  name: 'staffClient',
  firebase: {
    apiKey: 'AIzaSyBHwkcptHOXVXOmT5w5zWnLJZdTTZRxfSg',
    authDomain: 'service-work-dev.firebaseapp.com',
    databaseURL: 'https://service-work-dev.firebaseio.com',
    projectId: 'service-work-dev',
    storageBucket: 'service-work-dev.appspot.com',
    messagingSenderId: '228360618746',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error'; // Included with Angular CLI.
