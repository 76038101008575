import { ActionReducerMap, ActionReducer, MetaReducer } from '@ngrx/store';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { environment } from '../../environments/environment';
import * as RouterReducer from './router.state';
// import * as SafeRouterReducer from './safe-router.state';
// import {
//   State as SharedState,
//   reducers as sharedReducers,
// } from '@local/shared-environment';

export { CustomRouterStateSerializer } from './router.state';
// export { SafeRouterReducer };
// export { RouterReducer };

/**
 * storeFreeze prevents state from being mutated. When mutation occurs, an
 * exception will be thrown. This is useful during development mode to
 * ensure that none of the reducers accidentally mutates the state.
 */

/**
 * Every reducer module's default export is the reducer function itself. In
 * addition, each module should export a type or interface that describes
 * the state of the reducer plus any selector functions. The `* as`
 * notation packages up all of the exports into a single object.
 */

// import * as signup from '../ngrx/signup/reducers/signup';
// import * as signin from '../ngrx/signin/reducers';

/**
 * As mentioned, we treat each reducer like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 */
export interface State {
  routerReducer: RouterReducerState<RouterReducer.State>;
  // safeRouterReducer: RouterReducerState<RouterReducer.State>;
}

/**
 * Our state is composed of a map of action reducer functions.
 * These reducer functions are called with each dispatched action
 * and the current or initial state and return a new immutable state.
 */
export const reducers: ActionReducerMap<State> = {
  // ...sharedReducers,
  routerReducer: routerReducer,
  // safeRouterReducer: SafeRouterReducer.reducer as any,
};

// console.log all actions
export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
  return (state: State | undefined, action: any): State => {
    // if (action.type !== '[Cookie] Set all cookies') {
    console.debug('state', state);
    console.debug('action', action);
    // }

    return reducer(state, action);
  };
}

/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */
export const metaReducers: MetaReducer<State>[] = !environment.production
  ? [logger]
  : [];
